<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">类型选择：</span>
                    <el-select v-model="type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, idx) in list" :key="idx" @click="$router.push({ path: '/moocLearningDetail', query: { id: item.id } })">
                <img  v-if='item.moocChapterList.length>0&&item.moocChapterList[0].videoUrl1' class="active" :src="item.moocChapterList[0].videoUrl1 + '?x-oss-process=video/snapshot,t_0,f_jpg'" alt="" />
                <img v-else src="@/assets/images/Group1075.png" class="active" />
				<div class="info">
                    <div class="title">{{ item.curriculumName }}</div>
                    <div class="calligraphy" v-if="item.type">{{ options[item.type || 0].label }}</div>
                    <div class="calligraphy" v-else>无</div>
                    <div class="address">
                        <span>授课老师：{{ item.teacherName }}</span>
                    </div>
                    <div class="reading">
                        <img src="@/assets/images/Frame-38.png" alt="" />
                        {{ item.playerCount || 0 }}
                    </div>
                    <div class="go" @click="$router.push({ path: '/moocLearningDetail', query: { id: item.id } })">立即观看</div>
                </div>
            </div>
        </div>
        <el-pagination class="pagination" v-if="total" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '慕课学习',
                    to: { path: '/my', query: { tabs: '0' } },
                },
            ],
            options: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '书法',
                    value: 1,
                },
                {
                    label: '舞蹈',
                    value: 2,
                },
                {
                    label: '音乐',
                    value: 3,
                },
                {
                    label: '美术',
                    value: 4,
                },
                {
                    label: '摄影',
                    value: 5,
                },
                {
                    label: '文艺鉴赏',
                    value: 6,
                },
                {
                    label: '其他',
                    value: 7,
                },
            ],
            type: '',
            list: [],
            pageNum: 1,
            pageSize: 16,
            total: 0,
        };
    },
    watch: {
        type() {
            this.pageNum = 1;
            this.getData();
        },
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
				libraryId: localStorage.getItem('id')
            };
            if (this.type !== '') {
                params.type = this.type;
            }
            this.$hao.getMooclearnList(params).then((res) => {
                this.list = res.rows;
                this.total = res.total;
				this.list.forEach(item => {
					if (item.moocChapterList && item.moocChapterList.length>0){
						item.moocChapterList.forEach(mooc => {
							if (mooc.videoUrl) {
								mooc.videoUrl1 = mooc.videoUrl.split(',')[0];
							}
						})
					}
				})
            });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            .col {
                display: flex;
                align-items: center;
                margin-right: 24px;
                span {
                    font-size: 13px;
                    color: #333333;
                }
                .el-select {
                    width: 160px;
                }
                ::v-deep .el-input__inner {
                    border: 0;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 24px;
            }
            ::v-deep .el-input__inner,
            ::v-deep .el-input {
                border-radius: 20px;
                width: 240px;
                height: 30px;
            }
            ::v-deep .el-input__prefix {
                margin-top: -3px;
                //i {
                //    height: 30px;
                //}
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
        width: 100%;

        .item {
            display: flex;
            flex-direction: column;
            page-break-inside: avoid;
            width: 100%;
            background: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            margin-top: 24px;
            position: relative;

            .active {
                width: 100%;
                height: 210px;
            }

            .info {
                padding: 12px;

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 23px;
                    height: 46px;
                    color: #333333;
                    width: 100%;
                    .ellipsisLine(2);
                }
                .status {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 16px;
                    line-height: 23px;
                    color: #ffffff;
                    padding: 4px 12px;
                    background: #f33f3e;
                    border-radius: 2px 4px;
                }
                .calligraphy {
                    padding: 2px 5px;
                    border: 1px solid #f33f3e;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #f33f3e;
                    display: inline-block;
                }
                .address {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    span {
                        font-size: 13px;
                        line-height: 19px;
                        color: #999999;
                    }
                }
                .reading {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    color: #666666;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
                .go {
                    margin-top: 10px;
                    height: 44px;
                    box-shadow: 0px 2px 4px rgba(14, 79, 71, 0.2);
                    background: #eda200;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #ffffff;
                }
            }
        }
    }
    .pagination {
        margin: 20px auto;
    }
}
</style>
